<template>
  <div>
    <div class="text-lg-h6 mt-n1 d-flex justify-space-between">
      <div>Select Delegate Package</div>
      <div>
        <v-autocomplete
          :items="currencies"
          :label="`${currency === 'Local' ? '' : 'Select'} Currency`"
          outlined
          dense
          :disabled="currency === 'Local'"
          v-model="selectedCurrency"
        >
          <template v-slot:prepend-inner>
            <v-icon class="mt-2">mdi-cash</v-icon>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <div>
      <div class="packagesPod">
        <v-chip-group
          v-model="selectedPackages"
          column
          multiple
          active-class="primary lighten-4 primary--text text--darken-4"
        >
          <v-chip
            filter
            v-for="(item, i) in eventPackages"
            :key="i"
            :disabled="(item.mandatory && item.defaultPackage) || !isFormValid"
          >
            {{ item.description }} : {{ item.currencyCode }}
            <span v-if="isEarlyBird(item)">
              {{ item.earlyBirdAmountInclVAT | currencyFormat }}
            </span>
            <span v-else>{{ item.amountIncludingVAT | currencyFormat }}</span>
          </v-chip>
        </v-chip-group>
      </div>
    </div>
  </div>
</template>
<script>
import bookingMixin from "./bookingMixin";
export default {
  name: "DelegatePackages",
  mixins: [bookingMixin],
  props: {
    delegateForm: {
      type: Object,
      default: () => ({}),
    },
    currency: {
      type: String,
      default: "Local",
    },
    category: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedPackages: [],
      currencies: [],
      eventPackages: [],
      categories: [],
      isBooking: false,
      selectedCurrency: null,
      selectedPackagesArray: [],
    };
  },
  mounted() {
    this.setPackages(this.event.eventPackageCost || []);
    if (this.$route.query.bookingNo && this.$route.params.id !== undefined) {
      this.isBooking = true;
    }
  },
  computed: {
    booking() {
      return this.$store.getters["Events/booking"];
    },
    isFormValid() {
      return this.delegateForm.idNo && this.delegateForm.idNo.length > 0;
    },
    delegateTotal() {
      const total = {
        amountExclVAT: 0,
        amountInclVAT: 0,
        vatAmount: 0,
      };
      this.selectedPackages.forEach((pack) => {
        total.amountExclVAT += pack.amountExclVAT;
        total.amountInclVAT += pack.amountInclVAT;
        total.vatAmount += pack.vatAmount;
      });

      return total;
    },
  },
  methods: {
    isEarlyBird: function (eventPackage) {
      return new Date(eventPackage.earlyBirdCutoffDate) >= new Date();
    },
    setPackages: function (eventPackages) {
      this.eventPackages = this.event.eventPackageCost
        .filter((p) => p.attendanceType === this.delegateForm.attendanceType)
        .filter(
          (p) =>
            p.appliesType === this.delegateForm.delegateType ||
            p.appliesType === "" ||
            p.appliesType === " "
        )
        .filter(
          (p) =>
            p.membershipCategory === this.category ||
            p.membershipCategory === " " ||
            p.membershipCategory === ""
        );
      eventPackages.forEach((p) => {
        if (!this.currencies.includes(p.currencyCode))
          this.currencies.push(p.currencyCode);

        if (p.membershipCategory !== "")
          if (!this.categories.includes(p.membershipCategory))
            this.categories.push(p.membershipCategory);
      });

      this.selectedCurrency = this.currencies[0];
    },
    removePackage: function (selectedPackage) {
      this.selectedPackages.splice(
        this.selectedPackages.findIndex(
          (p) => p.packageNo === selectedPackage.packageNo
        ),
        1
      );
    },
    updatePackage: function () {
      if (this.decryptedRoute.params.id !== undefined) {
        // updated selected packages by saved packages on edit
        const savedpackages = [];
        console.log(this.delegateForm);
        this.delegateForm.bookingDelegatesPackage.map((el, i) => {
          if (
            this.event.eventPackageCost.find(
              (a) => a.packageNo === el.packageCode
            ) !== undefined
          ) {
            savedpackages.push(i);
          }
        });
        this.selectedPackages = savedpackages;
      } else {
        this.selectedPackages = this.eventPackages
          .map((p, i) => {
            if (p.mandatory) {
              return i;
            }
          })
          .filter((el) => el !== undefined);
      }
    },
    updateByCategory() {
      // filter for member delegates
      const isCategory = this.event.eventPackageCost.find(
        (p) => p.membershipCategory === this.category
      );
      this.eventPackages = this.event.eventPackageCost
        .filter((p) => p.attendanceType === this.delegateForm.attendanceType)
        .filter(
          (p) =>
            p.appliesType === this.delegateForm.delegateType ||
            p.appliesType === "" ||
            p.appliesType === " "
        )
        .filter((p) =>
          isCategory
            ? p.membershipCategory === this.category
            : p.membershipCategory === this.category ||
              p.membershipCategory === " " ||
              p.membershipCategory === ""
        );
    },
    getBooking() {
      this.$store.dispatch(
        "Events/getBooking",
        this.decryptedRoute.params.bookingNo
      );
    },
    cancelPackage: function (packageNo, delegateNo) {
      this.$confirm.show({
        title: "Remove Package",
        text: `Are you sure you want remove this package ?`,
        onConfirm: () => {
          this.$store.dispatch("Events/cancelPackage", {
            bookingNo: this.booking.bookingNo,
            eventNo: this.cpdEvent.no,
            delegateNo: delegateNo,
            email: this.booking.email,
            packageCode: packageNo,
            cpdEvent: this.eventBAsicInfo(),
          });
        },
      });
    },
  },
  watch: {
    selectedPackages: {
      handler: function () {
        const selectedPackages = [
          ...this.selectedPackages.map((el) => {
            this.eventPackages[el].packageCode =
              this.eventPackages[el].packageNo;
            return this.eventPackages[el];
          }),
        ];
        this.$store.commit(
          "Events/SET_SELECTED_AMOUNT",
          selectedPackages.reduce(
            (a, b) =>
              a +
              (this.isEarlyBird(b)
                ? b.earlyBirdAmountInclVAT
                : b.amountIncludingVAT),
            0
          )
        );
        this.selectedPackagesArray = selectedPackages;
        this.$emit("addPackagesToFormData", this.selectedPackagesArray);
      },
      deep: true,
    },
    "delegateForm.delegateType": function () {
      this.updateByCategory();
    },
    "delegateForm.attendanceType": function () {
      this.updateByCategory();
    },
    category: function () {
      console.log("updated category");
      this.updateByCategory();
    },
    event: {
      handler: function (event) {
        this.setPackages(event.eventPackageCost || []);
      },
      deep: true,
    },
    eventPackages: {
      handler: function () {
        this.updatePackage();
      },
      deep: true,
    },
  },
};
</script>
